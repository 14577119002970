import React from "react"
// import tw, { styled } from "twin.macro"
import "styled-components/macro"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Headlines from "../components/Headlines"
// import SocialMedia from "../components/SocialMedia"

// const Grid = styled.div`
//   ${tw`grid grid-cols-1 sm:grid-cols-2 gap-8 mt-12`}
// `
// const Header = styled.div`
//   ${tw`border-l-4 border-l-red-600 pl-4 font-bold`}
// `

// const Divider = styled.div`
//   ${tw`h-[1px] bg-gray-200 my-2`}
// `

const IndexPage = ({
  data: {
    markdownRemark: {
      frontmatter: { seo, hero },
    },
  },
}) => {
  return (
    <Layout
      seoTitle={seo?.title || "Home"}
      seoDescription={seo?.description}
      homePageData={hero}
    >
      <Headlines />
      {/* <Grid>
        <div>
          <Header>FOLLOW US</Header>
          <Divider />
          <SocialMedia
            iconWidth="24px"
            iconHeight="24px"
            iconColor="#ffffff"
            backgroundColor="#EC1F27"
            bgWidth="20%"
          />
        </div>
      </Grid> */}
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero {
          slides {
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            dots {
              enabled
              size
              color
            }
            overlay {
              enabled
              color
              opacity
            }
            video {
              publicURL
            }
            headers
            blurb
            buttons {
              icon
              label
              url
              color
              width
            }
            cutout {
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              position
              offset
            }
          }
        }
      }
    }
  }
`
